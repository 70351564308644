import * as Constants from '../../api/Constants';

export function parseReportDateString(value) {
    if (value !== undefined) {
        const today = new Date();
        if (value === Constants.FIRST_DAY_OF_MONTH) {
            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const epoch = Math.round((firstDay).getTime());
            return epoch;
        }
        if (value === Constants.LAST_DAY_OF_MONTH) {
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            const epoch = Math.round((lastDay).getTime());
            return epoch;
        }
        if (value === Constants.FIRST_DAY_OF_WEEK) {
            const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
            const epoch = Math.round((firstDay).getTime());
            return epoch;
        }
        if (value === Constants.LAST_DAY_OF_WEEK) {
            const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 6));
            const epoch = Math.round((lastDay).getTime());
            return epoch;
        }
        const dateArray = value.split('-');
        if (dateArray.length >= 3) {
            var date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
            const epoch = Math.round((date).getTime());
            return epoch;
        }
    }
    return undefined;
}