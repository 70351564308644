import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation';
import translationES from './locales/es/translation';
import translationPT_BR from './locales/pt-BR/translation';
// the translations
const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  "pt-BR": {
    translation: translationPT_BR
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources: resources,
    lng: 'pt-BR',
    fallbackLng: 'pt-BR',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;