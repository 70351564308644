/**
 * Constants
 */
export const STATIC = 'static';
export const CHART = 'chart';
export const FILTER = 'filter';
export const COMPONENT_TYPES = [STATIC, CHART, FILTER];

export const SLICER = 'slicer';
export const SINGLE_VALUE = 'single';
export const DATE_PICKER = 'date picker';

export const SLICER_DISPLAY = 'Lista valores';
export const SINGLE_VALUE_DISPLAY = 'Simples';
export const DATE_PICKER_DISPLAY = 'Seleção data';
export const FILTER_TYPES = [{ value: SLICER, display: SLICER_DISPLAY }, { value: SINGLE_VALUE, display: SINGLE_VALUE_DISPLAY }, { value: DATE_PICKER, display: DATE_PICKER_DISPLAY }];

export const FIRST_DAY_OF_MONTH = 'PRIMEIRO DIA MÊS';
export const LAST_DAY_OF_MONTH = 'ÚLTIMO DIA MÊS';
export const FIRST_DAY_OF_WEEK = 'PRIMEIRO DIA SEMANA';
export const LAST_DAY_OF_WEEK = 'ÚLTIMO DIA SEMANA';

export const DAYS_TYPES = [FIRST_DAY_OF_MONTH, LAST_DAY_OF_MONTH, FIRST_DAY_OF_WEEK,
    LAST_DAY_OF_WEEK];
export const PIE = 'pie';
export const TABLE = 'table';
export const LINE = 'line';
export const AREA = 'area';
export const BAR = 'bar';
export const HEATMAP = 'heatmap';
export const TREEMAP = 'treemap';
export const FUNNEL = 'funnel';
export const CARD = 'card';
export const KANBAN = 'kanban';

export const PIE_DISPLAY = 'Pizza';
export const TABLE_DISPLAY = 'Tabela';
export const LINE_DISPLAY = 'Linha';
export const AREA_DISPLAY = 'Área';
export const BAR_DISPLAY = 'Barras';
export const HEATMAP_DISPLAY = 'Mapa calor';
export const TREEMAP_DISPLAY = 'Mapa em árvore';
export const FUNNEL_DISPLAY = 'Funil';
export const CARD_DISPLAY = 'Cartão';
export const KANBAN_DISPLAY = 'Kanban';
export const CHART_TYPES =
    [{ value: TABLE, display: TABLE_DISPLAY },
    { value: PIE, display: PIE_DISPLAY },
    { value: LINE, display: LINE_DISPLAY },
    { value: BAR, display: BAR_DISPLAY },
    { value: AREA, display: AREA_DISPLAY },
    { value: CARD, display: CARD_DISPLAY },
    { value: FUNNEL, display: FUNNEL_DISPLAY },
    { value: TREEMAP, display: TREEMAP_DISPLAY },
    { value: HEATMAP, display: HEATMAP_DISPLAY },
    { value: KANBAN, display: KANBAN_DISPLAY }];

export const IMAGE = 'image';
export const TEXT = 'text';
export const HTML = 'html';
export const IFRAME = 'iframe';

export const IMAGE_DISPLAY = 'Imagem';
export const TEXT_DISPLAY = 'Texto';
export const HTML_DISPLAY = 'HTML';
export const IFRAME_DISPLAY = 'Frame HTML';
export const STATIC_TYPES = [{ value: TEXT, display: TEXT_DISPLAY }, { value: IMAGE, display: IMAGE_DISPLAY }, { value: IFRAME, display: IFRAME_DISPLAY }, { value: HTML, display: HTML_DISPLAY }];


export const DEFAULT_FILTER_VIEW_WIDTH = 200;

export const SYS_ROLE_ADMIN = 'admin';
export const SYS_ROLE_DEVELOPER = 'developer';
export const SYS_ROLE_VIEWER = 'viewer';

export const DEFAULT_REPORT_HEIGHT = 600;
export const DEFAULT_REPORT_FIXED_WIDTH = 800;

export const REMEMBERME = 'rememberMe';
export const YES = 'yes';
export const NO = 'no';

export const COLOR_SLATE = 'rgba(9, 30, 66, 1)';
export const COLOR_WHITE = 'rgba(255, 255, 255, 1)';

export const DEFAULT = 'default';
export const VINTAGE = 'vintage';
export const ROMA = 'roma';
export const MACARONS = 'macarons';
export const SHINE = 'shine';
export const CHART_COLOR_PLATETTES = [DEFAULT, VINTAGE, ROMA, MACARONS, SHINE];

export const ADHOC = 'adhoc';
export const CANNED = 'canned';

export const DEFAULT_MIN_COLOR = 'rgba(222, 53, 11, 1)';
export const DEFAULT_MAX_COLOR = 'rgba(0, 135, 90, 1)';

export const REPORT = 'report';

