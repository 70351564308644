
import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import mandala_background from '../../assets/img/background.jpg';


import './Login.css';
import Checkbox from '../../components/Checkbox/Checkbox';
import * as Constants from '../../api/Constants';


class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      version: '',
      rememberMe: true,
      localeLanguage: 'pt-BR'
    };
  }

  componentDidMount() {
    const thisNode = ReactDOM.findDOMNode(this);
    if (thisNode) {
      const { ownerDocument } = thisNode;
      ownerDocument.addEventListener("keydown", this.onKeyDown);
    }

    axios.get('/info/general')
      .then(res => {
        const info = res.data;
        const {
          version,
          localeLanguage
        } = info;
        this.setState({
          version: version,
          localeLanguage: localeLanguage
        }, () => {
          const { i18n } = this.props;
          i18n.changeLanguage(String(this.state.localeLanguage));
        });
      });

    const rememberMeConfig = localStorage.getItem(Constants.REMEMBERME);
    const rememberMe = rememberMeConfig && rememberMeConfig === Constants.YES;

    this.setState({
      rememberMe: rememberMe
    });
  }

  componentWillUnmount() {
    const thisNode = ReactDOM.findDOMNode(this);
    if (thisNode) {
      const { ownerDocument } = thisNode;
      ownerDocument.removeEventListener('keydown', this.onKeyDown);
    }
  }

  handleCheckBoxChange = (name, isChecked) => {
    this.setState({
      [name]: isChecked
    });

    if (name === Constants.REMEMBERME) {
      const value = isChecked ? Constants.YES : Constants.NO;
      localStorage.setItem(Constants.REMEMBERME, value);
    }
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.login();
    }
  }

  login = () => {
    const {
      username,
      password
    } = this.state;

    const user = {
      username: username,
      password: password
    }

    if (!username) {
      toast.error('Informe o nome do usuário');
      return;
    }

    if (!password) {
      toast.error('Informe a senha');
      return;
    }

    axios.post('/auth/login/user', user)
      .then(res => {
        const loginResponse = res.data;
        if (loginResponse.error) {
          toast.error(loginResponse.error);
        } else {
          axios.defaults.headers.common['Authorization'] = loginResponse.sessionKey;
          this.props.onLoginSuccess(loginResponse);
        }
      });
  }

  render() {
    const { t } = this.props;

    return (
      <div className='login-view' style={{
        width: "100%", height: "100%",
        backgroundImage: `url(${mandala_background})`,
        backgroundSize: "cover"
      }}>
        <div className="login-panel">
          <div className="login-panel-body">
            <div className="login-app-title">
              <img alt="" style={{ width: "200px" }} src={require("../../assets/img/mandala_logo_banner.png")} />
              Report Web
            </div>
            <div className="form-panel" style={{ padding: "15px" }}>
              <label>{t('Username')}</label>
              <input
                className="form-input login-input"
                type="text"
                name="username"
                value={this.state.username}
                onChange={this.handleInputChange}
              />
              <label>{t('Password')}</label>
              <input
                className="form-input login-input"
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleInputChange}
              />
              <div style={{ marginBottom: '8px' }}>
                <Checkbox name="rememberMe" value={t('Remember me')} checked={this.state.rememberMe} onChange={this.handleCheckBoxChange} />
              </div>
              <button className="button login-button button-green" onClick={this.login}>{t('Login')}</button>
            </div>
          </div>
        </div>
        <div className="version-number">
          {t('Version')}: {this.state.version} {t('Locale')}: {this.state.localeLanguage}
        </div>
      </div>
    )
  }
}

export default (withTranslation()(withRouter(Login)));