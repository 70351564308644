
import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Modal from '../components/Modal/Modal';
import SearchInput from '../components/SearchInput/SearchInput';
import oracle from '../assets/img/oracle.png';
import mysql from '../assets/img/mysql.png';
import sqlite from '../assets/img/sqlite.png';
import postgresql from '../assets/img/postgresql.png';
import mssqlserver from '../assets/img/mssqlserver.png';
import mariadb from '../assets/img/mariadb.png';
import others from '../assets/img/others.png';
import { default as ReactSelect } from 'react-select';
import * as ReactSelectHelper from './Studio/ReactSelectHelper';

const dataSourceTypes = [
  { value: 'Oracle', label: 'Oracle', driverClassName: 'oracle.jdbc.pool.OracleDataSource', image: oracle },
  { value: 'PostgreSQL', label: 'PostgreSQL', driverClassName: 'org.postgresql.Driver', image: postgresql },
  { value: 'MySQL', label: 'MySQL', driverClassName: 'com.mysql.jdbc.Driver', image: mysql },
  { value: 'SQLite', label: 'SQLite', driverClassName: 'org.sqlite.JDBC', image: sqlite },
  { value: 'MariaDB', label: 'MariaDB', driverClassName: 'org.mariadb.jdbc.Driver', image: mariadb },
  { value: 'MSSqlServer', label: 'MSSqlServer', driverClassName: 'com.microsoft.sqlserver.jdbc.SQLServerConnectionPoolDataSource', image: mssqlserver },
  { value: 'Others', label: 'Others', driverClassName: '', image: others },
];

class DataSource extends Component {

  constructor(props) {
    super(props);
    this.state = {
      jdbcDataSources: [],
      showEditPanel: false,
      showConfirmDeletionPanel: false,
      objectToDelete: {},
      showUpdatePassword: false,
      searchValue: '',
      id: null,
      name: '',
      dataSourceType: '',
      connectionUrl: '',
      driverClassName: '',
      username: '',
      password: '',
      ping: ''
    };
  }

  get initialState() {
    return {
      showUpdatePassword: false,
      id: null,
      name: '',
      connectionUrl: '',
      driverClassName: '',
      username: '',
      password: '',
      ping: ''
    };
  }

  componentDidMount() {
    this.fetchDataSources();
  }

  fetchDataSources() {
    axios.get('/ws/jdbcdatasources')
      .then(res => {
        const jdbcDataSources = res.data;
        this.setState({
          jdbcDataSources: jdbcDataSources
        });
      });
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleDataSourceTypeChange = (selectedOption) => {
    this.setState({ dataSourceType: selectedOption, driverClassName: selectedOption.driverClassName });
  };

  handleNameInputChange = (name, value) => {
    this.setState({
      [name]: value
    });
  }

  save = () => {
    const {
      showUpdatePassword,
      id,
      dataSourceType,
      connectionUrl,
      driverClassName,
      username,
      password,
      name,
      ping,
    } = this.state;

    if (!name) {
      toast.error('Informe o nome da fonte de dados.');
      return;
    }

    let ds = {
      connectionUrl: connectionUrl,
      dataSourceType: dataSourceType.value,
      driverClassName: driverClassName,
      username: username,
      name: name,
      ping: ping
    };

    if (id !== null) {
      ds.id = id;
      if (showUpdatePassword && password) {
        ds.password = password;
      }

      // Update
      axios.put('/ws/jdbcdatasources', ds)
        .then(res => {
          this.closeEditPanel();
          this.fetchDataSources();
        })
        .catch(error => {
          toast.error('Já existe uma fonte de dados com este nome. Tente outro nome.');
        });
    } else {
      ds.password = password;

      axios('/ws/jdbcdatasources', { data: ds, method: 'post' })
        .then(res => {
          this.closeEditPanel();
          this.fetchDataSources();
        })
        .catch(error => {
          toast.error('Já existe uma fonte de dados com este nome. Tente outro nome.');
        });
    }
  }

  ping = (id) => {
    axios.get(`/ws/jdbcdatasources/ping/${id}`)
      .then(res => {
        const result = res.data;
        if (result === 'success') {
          toast.success('Ping realizado com sucesso');
        } else {
          toast.error(result);
        }
      });
  }

  clearEditPanel = () => {
    this.setState(this.initialState);
  }

  openEditPanel = (ds) => {
    this.clearEditPanel();
    if (ds !== null) {
      let dataSourceType;
      var index = dataSourceTypes.findIndex(item => item.value === ds.dataSourceType);
      if (index >= 0) {
        dataSourceType = dataSourceTypes[index];
      }
      this.setState({
        showUpdatePassword: false,
        id: ds.id,
        dataSourceType: dataSourceType,
        connectionUrl: ds.connectionUrl,
        driverClassName: ds.driverClassName,
        username: ds.username,
        name: ds.name,
        ping: ds.ping
      });
    }

    this.setState({
      showEditPanel: true
    });
  }

  closeEditPanel = () => {
    this.setState({
      showEditPanel: false
    });
  }

  confirmDelete = () => {
    const {
      objectToDelete = {}
    } = this.state;
    axios.delete('/ws/jdbcdatasources/' + objectToDelete.id)
      .then(res => {
        this.fetchDataSources();
        this.closeConfirmDeletionPanel();
      });
  }

  openConfirmDeletionPanel = (datasource) => {
    this.setState({
      objectToDelete: datasource,
      showConfirmDeletionPanel: true
    });
  }

  closeConfirmDeletionPanel = () => {
    this.setState({
      objectToDelete: {},
      showConfirmDeletionPanel: false
    });
  }

  toggleUpdatePassword = () => {
    this.setState(prevState => ({
      showUpdatePassword: !prevState.showUpdatePassword
    }));
  }

  render() {
    const { t } = this.props;

    const {
      showUpdatePassword,
      id,
      jdbcDataSources = [],
      searchValue,
      showConfirmDeletionPanel,
      objectToDelete = {}
    } = this.state;

    const mode = id === null ? 'New' : 'Edit';

    const jdbcDataSourceItems = [];
    for (let i = 0; i < jdbcDataSources.length; i++) {
      const ds = jdbcDataSources[i];
      const {
        id,
        name
      } = ds;
      var index = dataSourceTypes.findIndex(item => item.value === ds.dataSourceType);

      let imgDatasource;
      if (index >= 0) {
        imgDatasource = <img alt="" style={{ width: "100px" }} src={dataSourceTypes[index].image} />;
      }
      if (!searchValue || (searchValue && name.includes(searchValue))) {
        jdbcDataSourceItems.push(
          (
            <div key={id} className="card float-left">
              <div className="card-header ellipsis">
                {name}
              </div>
              <div className="card-content" style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                {imgDatasource}
              </div>
              <div className="card-footer row">
                <div className="float-right">
                  <button className="icon-button card-icon-button" onClick={() => this.openEditPanel(ds)}>
                    <FontAwesomeIcon icon="edit" />
                  </button>
                  <button className="icon-button card-icon-button" onClick={() => this.openConfirmDeletionPanel(ds)}>
                    <FontAwesomeIcon icon="trash-alt" />
                  </button>
                  <button className="icon-button card-icon-button" onClick={() => this.ping(ds.id)}>
                    <FontAwesomeIcon icon="plug" />
                  </button>
                </div>
              </div>
            </div>
          )
        )
      }
    }

    return (
      <div className="full-page-content">
        <div class="row">
          <div className="float-left" style={{ lineHeight: '33px', fontWeight: 700, marginRight: '15px' }}>
            {t('Data Source')}
          </div>
          <button className="button float-left" style={{ marginRight: '5px' }} onClick={() => this.openEditPanel(null)}>
            <FontAwesomeIcon icon="plus" /> {t('New')}
          </button>
          <div className="float-left">
            <SearchInput
              id="_searchValue"
              name={'searchValue'}
              value={this.state.searchValue}
              onChange={this.handleNameInputChange}
              inputWidth={200}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: '8px' }}>
          {jdbcDataSourceItems}
        </div>

        <Modal
          show={this.state.showEditPanel}
          onClose={this.closeEditPanel}
          modalClass={'mid-modal-panel'}
          title={t(mode)} >

          <div className="form-panel">
            <label>{t('Name')} <span className="required">*</span></label>
            <input
              className="form-input"
              type="text"
              name="name"
              value={this.state.name}
              onChange={this.handleInputChange}
            />
            <label>{t('Type')} <span className="required">*</span></label>
            <ReactSelect
              placeholder={'Selecione o tipo da fonte...'}
              value={this.state.dataSourceType}
              defaultValue={this.state.dataSourceType}
              onChange={this.handleDataSourceTypeChange}
              options={dataSourceTypes}
              styles={ReactSelectHelper.CUSTOM_STYLE}
            />
            <label>{t('Connection Url')}</label>
            <textarea
              className="form-input"
              rows="4"
              type="text"
              name="connectionUrl"
              value={this.state.connectionUrl}
              onChange={this.handleInputChange} >
            </textarea>

            <label>{t('Driver Class Name')}</label>
            <input
              className="form-input"
              type="text"
              name="driverClassName"
              value={this.state.driverClassName}
              onChange={this.handleInputChange}
            />

            <label>{t('Username')}</label>
            <input
              className="form-input"
              type="text"
              name="username"
              value={this.state.username}
              onChange={this.handleInputChange}
            />

            {mode === 'Edit' && (
              <div style={{ margin: '3px 0px 8px 0px' }}>
                <button className="button" onClick={this.toggleUpdatePassword}>{t('Change Password')}</button>
              </div>
            )}

            {(mode === 'New' || showUpdatePassword) && (
              <div>
                <label>{t('New Password')}</label>
                <input
                  className="form-input"
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                />
              </div>
            )}

            <label>{t('Ping')}</label>
            <input
              className="form-input"
              type="text"
              name="ping"
              value={this.state.ping}
              onChange={this.handleInputChange}
            />
            <button className="button mt-3 button-green" onClick={this.save}>
              <FontAwesomeIcon icon="save" fixedWidth /> {t('Save')}
            </button>
          </div>
        </Modal>

        <Modal
          show={showConfirmDeletionPanel}
          onClose={this.closeConfirmDeletionPanel}
          modalClass={'small-modal-panel'}
          title={t('Confirm Deletion')} >
          <div className="confirm-deletion-panel">
            {t('Are you sure you want to delete')} {objectToDelete.name}?
          </div>
          <button className="button button-red full-width" onClick={this.confirmDelete}>{t('Delete')}</button>
        </Modal>
      </div>
    );
  }
}

export default (withTranslation()(DataSource));

